import React from 'react';
import { motion } from 'framer-motion';
import { X, Calendar, Clock, User, FileText, Tag } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CalendarEvent } from '../../context/CalendarContext';
import { useCalendar } from '../../context/CalendarContext';
import { useClients } from '../../context/ClientContext';

interface EventDetailsModalProps {
  event: CalendarEvent | null;
  onClose: () => void;
}

const EventDetailsModal: React.FC<EventDetailsModalProps> = ({ event, onClose }) => {
  const { updateEvent, deleteEvent } = useCalendar();
  const { clients } = useClients();
  
  if (!event) return null;

  const client = clients.find(c => c.id === event.clientId);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'scheduled':
        return 'bg-blue-100 text-blue-800';
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: string) => {
    const labels: { [key: string]: string } = {
      scheduled: 'Planifié',
      completed: 'Terminé',
      cancelled: 'Annulé'
    };
    return labels[status] || status;
  };

  const getTypeLabel = (type: string) => {
    const labels: { [key: string]: string } = {
      maintenance: 'Maintenance',
      installation: 'Installation',
      depannage: 'Dépannage',
      autre: 'Autre'
    };
    return labels[type] || type;
  };

  const handleStatusChange = (newStatus: CalendarEvent['status']) => {
    updateEvent(event.id, { status: newStatus });
  };

  const handleDelete = () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce rendez-vous ?')) {
      deleteEvent(event.id);
      onClose();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg shadow-xl max-w-md w-full"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold text-gray-900">{event.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          <div className="flex items-center justify-between">
            <span className={`px-2 py-1 rounded-full text-sm ${getStatusColor(event.status)}`}>
              {getStatusLabel(event.status)}
            </span>
            <span className="text-sm text-gray-500">
              Type: {getTypeLabel(event.type)}
            </span>
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Calendar className="h-5 w-5" />
              <span>{format(event.start, 'EEEE d MMMM yyyy', { locale: fr })}</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>
                {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
              </span>
            </div>
            {client && (
              <div className="flex items-center space-x-2 text-gray-600">
                <User className="h-5 w-5" />
                <span>{client.firstName} {client.lastName} - {client.company}</span>
              </div>
            )}
            {event.description && (
              <div className="flex items-start space-x-2 text-gray-600">
                <FileText className="h-5 w-5 mt-0.5" />
                <p>{event.description}</p>
              </div>
            )}
          </div>

          <div className="border-t pt-4 mt-4">
            <div className="flex justify-between items-center">
              <div className="space-x-2">
                <button
                  onClick={() => handleStatusChange('completed')}
                  className="px-3 py-1 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
                >
                  Marquer comme terminé
                </button>
                <button
                  onClick={() => handleStatusChange('cancelled')}
                  className="px-3 py-1 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  Annuler
                </button>
              </div>
              <button
                onClick={handleDelete}
                className="text-red-600 hover:text-red-700"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default EventDetailsModal;