import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, X, Send, AlertCircle, Check } from 'lucide-react';
import { sendEmail } from '../utils/emailService';
import { generatePDF } from '../utils/pdfGenerator';
import { useClients } from '../context/ClientContext';
import { useQuotes } from '../context/QuoteContext';
import { useInvoices } from '../context/InvoiceContext';

interface SendDocumentButtonProps {
  type: 'devis' | 'facture';
  documentNumber: string;
  clientEmail: string;
  clientName: string;
  documentId: number;
}

const SendDocumentButton: React.FC<SendDocumentButtonProps> = ({
  type,
  documentNumber,
  clientEmail,
  clientName,
  documentId
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const { clients } = useClients();
  const { quotes } = useQuotes();
  const { invoices } = useInvoices();

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);
    setStatus('idle');

    try {
      // Récupérer les données du document
      const document = type === 'devis' 
        ? quotes.find(q => q.id === documentId)
        : invoices.find(i => i.id === documentId);

      const client = clients.find(c => c.id === document?.clientId);

      if (!document || !client) {
        throw new Error('Document ou client introuvable');
      }

      // Générer le PDF
      const pdfData = generatePDF({
        type,
        number: documentNumber,
        date: document.date,
        validUntil: 'validUntil' in document ? document.validUntil : undefined,
        dueDate: 'dueDate' in document ? document.dueDate : undefined,
        client,
        items: document.items,
        notes: document.notes,
        terms: document.terms
      });

      // Envoyer l'email avec le PDF en pièce jointe
      await sendEmail({
        to_email: clientEmail,
        to_name: clientName,
        subject: `${type === 'devis' ? 'Devis' : 'Facture'} ${documentNumber}`,
        message: message,
        document_type: type,
        document_number: documentNumber,
        pdf_data: pdfData
      });

      setStatus('success');
      setTimeout(() => {
        setIsModalOpen(false);
        setStatus('idle');
        setMessage('');
      }, 2000);
    } catch (error) {
      console.error('Erreur lors de l\'envoi:', error);
      setStatus('error');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsModalOpen(true)}
        className="inline-flex items-center px-3 py-1 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
      >
        <Mail className="h-4 w-4 mr-2" />
        Envoyer par email
      </motion.button>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
            onClick={() => setIsModalOpen(false)}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="bg-white rounded-lg shadow-xl max-w-md w-full"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex justify-between items-center p-4 border-b">
                <h3 className="text-lg font-semibold text-gray-900">
                  Envoyer {type === 'devis' ? 'le devis' : 'la facture'} par email
                </h3>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <form onSubmit={handleSend} className="p-4 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Destinataire
                  </label>
                  <input
                    type="email"
                    value={clientEmail}
                    disabled
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm"
                  />
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={`Bonjour ${clientName},\n\nVeuillez trouver ci-joint ${type === 'devis' ? 'le devis' : 'la facture'} ${documentNumber}.\n\nCordialement`}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                {status === 'error' && (
                  <div className="text-sm text-red-600 flex items-center">
                    <AlertCircle className="h-4 w-4 mr-2" />
                    Une erreur est survenue lors de l'envoi
                  </div>
                )}

                {status === 'success' && (
                  <div className="text-sm text-green-600 flex items-center">
                    <Check className="h-4 w-4 mr-2" />
                    Email envoyé avec succès
                  </div>
                )}

                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    disabled={isSending}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none disabled:opacity-50"
                  >
                    {isSending ? (
                      <>
                        <span className="animate-spin mr-2">⌛</span>
                        Envoi...
                      </>
                    ) : (
                      <>
                        <Send className="h-4 w-4 mr-2" />
                        Envoyer
                      </>
                    )}
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SendDocumentButton;