import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';
import DashboardNav from '../components/DashboardNav';
import DashboardHome from './dashboard/DashboardHome';
import ClientsManager from './dashboard/ClientsManager';
import InvoicesManager from './dashboard/InvoicesManager';
import QuotesManager from './dashboard/QuotesManager';
import TasksManager from './dashboard/TasksManager';
import CalendarManager from './dashboard/CalendarManager';
import SettingsManager from './dashboard/SettingsManager';
import AppointmentsManager from './dashboard/AppointmentsManager';

const Dashboard = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <DashboardNav />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Routes>
          <Route index element={<DashboardHome />} />
          <Route path="clients/*" element={<ClientsManager />} />
          <Route path="quotes/*" element={<QuotesManager />} />
          <Route path="invoices/*" element={<InvoicesManager />} />
          <Route path="tasks/*" element={<TasksManager />} />
          <Route path="calendar/*" element={<CalendarManager />} />
          <Route path="appointments" element={<AppointmentsManager />} />
          <Route path="settings" element={<SettingsManager />} />
        </Routes>
      </div>
    </motion.div>
  );
};

export default Dashboard;