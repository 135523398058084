import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Search, User, Building2, Mail, Phone, Tag, MapPin } from 'lucide-react';
import { useClients } from '../context/ClientContext';
import Loader from './Loader';

const ClientsList = () => {
  const navigate = useNavigate();
  const { clients, loading, error } = useClients();
  const [searchTerm, setSearchTerm] = useState('');

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600">Une erreur est survenue lors du chargement des clients</p>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    return status === 'actif' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800';
  };

  const filteredClients = clients.filter(client => {
    const searchString = searchTerm.toLowerCase();
    return (
      client.firstName.toLowerCase().includes(searchString) ||
      client.lastName.toLowerCase().includes(searchString) ||
      client.company.toLowerCase().includes(searchString) ||
      client.email.toLowerCase().includes(searchString)
    );
  });

  return (
    <div className="bg-gradient-to-br from-emerald-50 via-white to-emerald-50 rounded-lg shadow-sm">
      {/* Header avec recherche et bouton d'ajout */}
      <div className="p-8 border-b border-emerald-100">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <div className="relative flex-1 max-w-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-emerald-400" />
            </div>
            <input
              type="text"
              placeholder="Rechercher un client..."
              className="pl-10 block w-full rounded-md border-emerald-200 shadow-sm focus:ring-emerald-500 focus:border-emerald-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/dashboard/clients/new')}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
            <Plus className="h-5 w-5 mr-2" />
            Nouveau client
          </motion.button>
        </div>
      </div>

      {/* Liste des clients */}
      <div className="p-4">
        <div className="min-w-full rounded-lg overflow-hidden bg-white">
          <table className="min-w-full table-fixed divide-y divide-emerald-100">
            <thead className="bg-emerald-50">
              <tr>
                <th className="w-1/4 px-4 py-3 text-left text-xs font-medium text-emerald-500 uppercase tracking-wider">
                  Client
                </th>
                <th className="w-1/4 px-4 py-3 text-left text-xs font-medium text-emerald-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="w-1/4 px-4 py-3 text-left text-xs font-medium text-emerald-500 uppercase tracking-wider">
                  Adresse
                </th>
                <th className="w-1/4 px-4 py-3 text-left text-xs font-medium text-emerald-500 uppercase tracking-wider">
                  Type
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-emerald-50">
              <AnimatePresence>
                {filteredClients.map((client) => (
                  <motion.tr
                    key={client.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    whileHover={{ backgroundColor: 'rgba(16, 185, 129, 0.1)' }}
                    className="cursor-pointer"
                    onClick={() => navigate(`/dashboard/clients/view/${client.id}`)}
                  >
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10 bg-emerald-100 rounded-full flex items-center justify-center">
                          <User className="h-5 w-5 text-emerald-600" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {client.firstName} {client.lastName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {client.company}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="text-sm text-gray-900 flex items-center">
                        <Mail className="h-4 w-4 text-gray-400 mr-1" />
                        {client.email}
                      </div>
                      <div className="text-sm text-gray-900 flex items-center mt-1">
                        <Phone className="h-4 w-4 text-gray-400 mr-1" />
                        {client.phone}
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm text-gray-900">
                        <MapPin className="h-4 w-4 text-gray-400 mr-1" />
                        {client.address}
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(client.status)}`}>
                          {client.status}
                        </span>
                        <Tag className="h-4 w-4 text-gray-400 ml-2" />
                        <span className="text-sm text-gray-500 ml-1">
                          {client.type}
                        </span>
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>

          {filteredClients.length === 0 && (
            <div className="text-center py-12">
              <User className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun client</h3>
              <p className="mt-1 text-sm text-gray-500">
                Commencez par créer un nouveau client.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientsList;