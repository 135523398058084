import emailjs from '@emailjs/browser';

interface EmailParams {
  to_email: string;
  to_name: string;
  subject: string;
  message: string;
  document_type: string;
  document_number: string;
  pdf_data?: string;
}

export const sendEmail = async (params: EmailParams) => {
  try {
    const templateParams = {
      to_email: params.to_email,
      to_name: params.to_name,
      subject: params.subject,
      message: params.message,
      document_type: params.document_type,
      document_number: params.document_number,
      pdf_attachment: params.pdf_data
    };

    const response = await emailjs.send(
      'service_ih9c3p9',
      'template_9gwbt5t',
      templateParams,
      'kzieKYxIkNc9aiUZl'
    );

    return response;
  } catch (error) {
    console.error('Erreur lors de l\'envoi de l\'email:', error);
    throw new Error('Échec de l\'envoi de l\'email');
  }
};