import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ClipboardList, Users, FileText, Cloud, ArrowRight, CheckCircle, Smartphone, Globe, Sparkles, Calendar, Clock, MapPin } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useActivity } from '../../context/ActivityContext';
import { useClients } from '../../context/ClientContext';
import { useInvoices } from '../../context/InvoiceContext';
import { useTasks } from '../../context/TaskContext';
import { useCalendar } from '../../context/CalendarContext';
import QuickLocation from '../../components/location/QuickLocation';
import RevenueChart from '../../components/dashboard/RevenueChart';
import { calculateRoute } from '../../utils/geocoding';

const DashboardHome = () => {
  const { user } = useAuth();
  const { activities } = useActivity();
  const { clients } = useClients();
  const { invoices } = useInvoices();
  const { tasks } = useTasks();
  const { events } = useCalendar();
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition | null>(null);
  const [departureInfo, setDepartureInfo] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setCurrentLocation(position),
        (error) => console.error('Error getting location:', error)
      );
    }
  }, []);

  useEffect(() => {
    const calculateDepartureTimes = async () => {
      if (!currentLocation || !events.length || !clients.length) return;
      
      const newDepartureInfo: { [key: string]: any } = {};

      for (const event of events) {
        const client = clients.find(c => c.id === event.clientId);
        if (!client?.location) continue;

        try {
          const routeInfo = await calculateRoute(
            {
              lat: currentLocation.coords.latitude,
              lng: currentLocation.coords.longitude
            },
            client.location
          );

          const appointmentTime = new Date(event.start);
          const departureTime = new Date(appointmentTime.getTime() - (routeInfo.durationValue * 1000));

          newDepartureInfo[event.id] = {
            duration: routeInfo.duration,
            distance: routeInfo.distance,
            departureTime: formatDepartureTime(departureTime)
          };
        } catch (err) {
          console.error('Error calculating route:', err);
        }
      }

      setDepartureInfo(newDepartureInfo);
    };

    calculateDepartureTimes();
  }, [currentLocation, events, clients]);

  const formatDepartureTime = (date: Date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return `aujourd'hui à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return `demain à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return `le ${date.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })} à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    }
  };

  const activeClients = clients.filter(client => client.status === 'actif').length;
  const currentMonthInvoices = invoices.filter(invoice => {
    const invoiceDate = new Date(invoice.date);
    const currentDate = new Date();
    return invoiceDate.getMonth() === currentDate.getMonth() &&
           invoiceDate.getFullYear() === currentDate.getFullYear();
  }).length;
  const pendingTasks = tasks.filter(task => !task.completed).length;

  const stats = [
    { 
      label: 'Clients actifs', 
      value: activeClients.toString(), 
      icon: Users, 
      link: '/dashboard/clients',
      color: 'text-emerald-600',
      bgColor: 'bg-emerald-50'
    },
    { 
      label: 'Factures du mois', 
      value: currentMonthInvoices.toString(), 
      icon: FileText,
      link: '/dashboard/invoices',
      color: 'text-violet-600',
      bgColor: 'bg-violet-50'
    },
    { 
      label: 'Tâches en attente', 
      value: pendingTasks.toString(), 
      icon: ClipboardList,
      link: '/dashboard/tasks',
      color: 'text-blue-600',
      bgColor: 'bg-blue-50'
    },
  ];

  const recentActivities = activities
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
    .slice(0, 5);

  const upcomingAppointments = events
    .filter(event => new Date(event.start) >= new Date())
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
    .slice(0, 3);

  const getActivityIcon = (type: string) => {
    switch (type) {
      case 'client':
        return Users;
      case 'invoice':
        return FileText;
      case 'quote':
        return FileText;
      case 'task':
        return ClipboardList;
      default:
        return FileText;
    }
  };

  const getActivityColor = (type: string) => {
    switch (type) {
      case 'client':
        return 'bg-emerald-100 text-emerald-600';
      case 'invoice':
        return 'bg-violet-100 text-violet-600';
      case 'quote':
        return 'bg-amber-100 text-amber-600';
      case 'task':
        return 'bg-blue-100 text-blue-600';
      default:
        return 'bg-gray-100 text-gray-600';
    }
  };

  return (
    <div>
      <div className="mb-8 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            Bonjour, {user?.displayName}
          </h1>
          <p className="text-gray-600">
            Bienvenue sur votre tableau de bord
          </p>
        </div>
        <QuickLocation />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {stats.map((stat, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className={`${stat.bgColor} p-6 rounded-xl shadow-sm`}
          >
            {stat.link ? (
              <Link to={stat.link} className="flex items-center justify-between group">
                <div>
                  <p className="text-sm font-medium text-gray-600">{stat.label}</p>
                  <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <stat.icon className={`h-8 w-8 ${stat.color}`} />
                  <ArrowRight className={`h-4 w-4 ${stat.color} opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all`} />
                </div>
              </Link>
            ) : (
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-600">{stat.label}</p>
                  <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
                </div>
                <stat.icon className={`h-8 w-8 ${stat.color}`} />
              </div>
            )}
          </motion.div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="min-h-[400px]"
        >
          <RevenueChart />
        </motion.div>

        <div className="space-y-6">
          {/* Upcoming Appointments */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="bg-white p-4 sm:p-6 rounded-xl shadow-sm"
          >
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <Calendar className="h-5 w-5 text-blue-600 mr-2" />
              Prochains rendez-vous
            </h2>
            <div className="space-y-4">
              {upcomingAppointments.map((appointment, index) => {
                const client = clients.find(c => c.id === appointment.clientId);
                const info = departureInfo[appointment.id];
                const isNextAppointment = index === 0;
                
                return (
                  <div 
                    key={appointment.id} 
                    className={`
                      flex flex-col sm:flex-row sm:items-center sm:justify-between p-4 
                      rounded-lg space-y-2 sm:space-y-0 transition-all duration-200
                      ${isNextAppointment ? 
                        'bg-blue-50 border-2 border-blue-200 shadow-md hover:shadow-lg scale-[1.02]' : 
                        'bg-gray-50 hover:bg-gray-100'
                      }
                    `}
                  >
                    <div className="space-y-1">
                      {isNextAppointment && (
                        <span className="inline-block px-2 py-1 text-xs font-medium text-blue-700 bg-blue-100 rounded-full mb-2">
                          Prochain rendez-vous
                        </span>
                      )}
                      <p className={`font-medium ${isNextAppointment ? 'text-blue-900' : 'text-gray-900'}`}>
                        {client ? `${client.firstName} ${client.lastName}` : 'Client inconnu'}
                      </p>
                      <p className={`text-sm flex items-center ${isNextAppointment ? 'text-blue-600' : 'text-gray-500'}`}>
                        <Calendar className="h-4 w-4 mr-1 flex-shrink-0" />
                        {new Date(appointment.start).toLocaleString('fr-FR', {
                          dateStyle: 'long',
                          timeStyle: 'short'
                        })}
                      </p>
                    </div>
                    {info && (
                      <div className={`text-sm flex flex-col sm:items-end ${isNextAppointment ? 'text-blue-600' : 'text-gray-500'}`}>
                        <p className="flex items-center">
                          <Clock className="h-4 w-4 mr-1 flex-shrink-0" />
                          Départ {info.departureTime}
                        </p>
                        <p className="flex items-center mt-1">
                          <MapPin className="h-4 w-4 mr-1 flex-shrink-0" />
                          {info.distance}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}

              {upcomingAppointments.length === 0 && (
                <p className="text-center text-gray-500 py-4">
                  Aucun rendez-vous à venir
                </p>
              )}

              <Link
                to="/dashboard/appointments"
                className="inline-flex items-center text-sm text-blue-600 hover:text-blue-700"
              >
                Voir tous les rendez-vous
                <ArrowRight className="h-4 w-4 ml-1" />
              </Link>
            </div>
          </motion.div>

          {/* Recent Activities */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="bg-white p-6 rounded-xl shadow-sm"
          >
            <h2 className="text-lg font-semibold text-gray-900 mb-4">
              Activité récente
            </h2>
            <div className="space-y-4">
              {recentActivities.map((activity) => {
                const ActivityIcon = getActivityIcon(activity.type);
                const colorClass = getActivityColor(activity.type);
                
                return (
                  <div key={activity.id} className="flex items-center space-x-4">
                    <div className={`h-10 w-10 rounded-full ${colorClass} flex items-center justify-center`}>
                      <ActivityIcon className="h-5 w-5" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {activity.description}
                      </p>
                      <p className="text-sm text-gray-500">
                        {new Date(activity.timestamp).toLocaleString()}
                      </p>
                    </div>
                  </div>
                );
              })}

              {recentActivities.length === 0 && (
                <p className="text-gray-500 text-center py-4">
                  Aucune activité récente
                </p>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;