import React from 'react';
import { Mail, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">vite-gestion</h3>
            <p className="text-sm">
              Solution professionnelle de gestion pour installateurs de climatisation
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <div className="space-y-2">
              <a href="tel:+33100000000" className="flex items-center space-x-2 text-sm hover:text-white">
                <Phone className="h-4 w-4" />
                <span>01 00 00 00 00</span>
              </a>
              <a href="mailto:contact@vite-gestion.fr" className="flex items-center space-x-2 text-sm hover:text-white">
                <Mail className="h-4 w-4" />
                <span>contact@vite-gestion.fr</span>
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Liens utiles</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-sm hover:text-white">CGV</a>
              </li>
              <li>
                <a href="#" className="text-sm hover:text-white">Mentions légales</a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-800 text-sm text-center">
          © {new Date().getFullYear()} vite-gestion. Tous droits réservés.
        </div>
      </div>
    </footer>
  );
};

export default Footer;