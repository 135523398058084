import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, MapPin, Calendar, ArrowRight } from 'lucide-react';
import { useCalendar } from '../../context/CalendarContext';
import { useClients } from '../../context/ClientContext';
import RouteMapModal from '../../components/location/RouteMapModal';
import { calculateRoute } from '../../utils/geocoding';
import Loader from '../../components/Loader';

interface DepartureInfo {
  duration: string;
  distance: string;
  departureTime: string;
}

const AppointmentsManager: React.FC = () => {
  const { events, loading } = useCalendar();
  const { clients } = useClients();
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition | null>(null);
  const [departureInfo, setDepartureInfo] = useState<{ [key: string]: DepartureInfo }>({});
  const [selectedAppointment, setSelectedAppointment] = useState<{
    event: any;
    client: any;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isCalculating, setIsCalculating] = useState(false);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur');
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setCurrentLocation(position);
        setError(null);
      },
      (err) => {
        setError('Impossible d\'obtenir votre position actuelle');
      },
      { enableHighAccuracy: true }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  useEffect(() => {
    const calculateDepartureTimes = async () => {
      if (!currentLocation || !events.length || !clients.length) return;
      
      setIsCalculating(true);
      const newDepartureInfo: { [key: string]: DepartureInfo } = {};

      try {
        for (const event of events) {
          const client = clients.find(c => c.id === event.clientId);
          if (!client?.location) continue;

          try {
            const routeInfo = await calculateRoute(
              {
                lat: currentLocation.coords.latitude,
                lng: currentLocation.coords.longitude
              },
              client.location
            );

            const appointmentTime = new Date(event.start);
            const departureTime = new Date(appointmentTime.getTime() - (routeInfo.durationValue * 1000));

            newDepartureInfo[event.id] = {
              duration: routeInfo.duration,
              distance: routeInfo.distance,
              departureTime: formatDepartureTime(departureTime)
            };
          } catch (err) {
            console.error('Erreur lors du calcul de l\'itinéraire:', err);
          }
        }

        setDepartureInfo(newDepartureInfo);
      } finally {
        setIsCalculating(false);
      }
    };

    calculateDepartureTimes();
  }, [currentLocation, events, clients]);

  const formatDepartureTime = (date: Date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return `aujourd'hui à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return `demain à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return `le ${date.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })} à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
    }
  };

  const getTypeLabel = (type: string) => {
    const labels: { [key: string]: string } = {
      maintenance: 'Maintenance',
      installation: 'Installation',
      depannage: 'Dépannage',
      other: 'Autre'
    };
    return labels[type] || type;
  };

  const getStatusLabel = (status: string) => {
    const labels: { [key: string]: string } = {
      scheduled: 'Planifié',
      completed: 'Terminé',
      cancelled: 'Annulé'
    };
    return labels[status] || status;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  if (loading) {
    return <Loader />;
  }

  // Filtrer les événements futurs et les trier par date
  const upcomingEvents = events
    .filter(event => new Date(event.start) >= new Date())
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-50 via-white to-pink-50">
      <div className="mb-8 relative p-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative z-10"
        >
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 to-pink-400">
            Mes rendez-vous
          </h1>
          <p className="mt-2 text-gray-600">
            Consultez vos prochains rendez-vous et temps de trajet
          </p>
        </motion.div>
      </div>

      <div className="container mx-auto px-4 py-6">
        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Client
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date et heure
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Distance
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Durée trajet
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Statut
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Départ conseillé
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {upcomingEvents.map((event) => {
                  const client = clients.find(c => c.id === event.clientId);
                  const info = departureInfo[event.id];

                  return (
                    <tr key={event.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm font-medium text-gray-900">
                              {client ? `${client.firstName} ${client.lastName}` : 'Client inconnu'}
                            </div>
                            <div className="text-sm text-gray-500">
                              {client?.company}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                          <span className="text-sm text-gray-900">
                            {new Date(event.start).toLocaleString('fr-FR', {
                              dateStyle: 'long',
                              timeStyle: 'short'
                            })}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900">
                          {getTypeLabel(event.type)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <MapPin className="h-4 w-4 text-gray-400 mr-2" />
                          <span className="text-sm text-gray-900">
                            {isCalculating ? 'Calcul...' : (info?.distance || 'Non disponible')}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Clock className="h-4 w-4 text-gray-400 mr-2" />
                          <span className="text-sm text-gray-900">
                            {isCalculating ? 'Calcul...' : (info?.duration || 'Non disponible')}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 text-xs font-medium rounded-full ${getStatusColor(event.status)}`}>
                          {getStatusLabel(event.status)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900">
                          {isCalculating ? 'Calcul...' : (info?.departureTime || 'Non disponible')}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {client?.location && currentLocation && (
                          <button
                            onClick={() => setSelectedAppointment({ event, client })}
                            className="text-pink-600 hover:text-pink-900 flex items-center"
                          >
                            Voir l'itinéraire
                            <ArrowRight className="h-4 w-4 ml-1" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}

                {upcomingEvents.length === 0 && (
                  <tr>
                    <td colSpan={8} className="px-6 py-4 text-center text-gray-500">
                      Aucun rendez-vous à venir
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {selectedAppointment && currentLocation && (
        <RouteMapModal
          isOpen={true}
          onClose={() => setSelectedAppointment(null)}
          currentLocation={currentLocation}
          destination={selectedAppointment.client.location}
          clientName={`${selectedAppointment.client.firstName} ${selectedAppointment.client.lastName}`}
        />
      )}
    </div>
  );
};

export default AppointmentsManager;