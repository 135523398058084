import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { GeoLocation } from '../../context/ClientContext';

interface MapViewProps {
  currentLocation: GeolocationPosition;
  clientLocation: GeoLocation;
}

const MapView: React.FC<MapViewProps> = ({ currentLocation, clientLocation }) => {
  const mapRef = React.useRef<L.Map>(null);

  React.useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current;
    const routingControl = L.Routing.control({
      waypoints: [
        L.latLng(currentLocation.coords.latitude, currentLocation.coords.longitude),
        L.latLng(clientLocation.lat, clientLocation.lng)
      ],
      routeWhileDragging: false,
      showAlternatives: false,
      fitSelectedRoutes: true,
      lineOptions: {
        styles: [{ color: '#6366f1', weight: 6 }]
      }
    }).addTo(map);

    // Ajuster le z-index de tous les éléments Leaflet
    const adjustLeafletZIndex = () => {
      const leafletContainers = document.querySelectorAll('.leaflet-container, .leaflet-control-container');
      leafletContainers.forEach((container) => {
        if (container instanceof HTMLElement) {
          container.style.zIndex = '1';
        }
      });
    };

    // Appliquer immédiatement et après un court délai pour s'assurer que tous les éléments sont chargés
    adjustLeafletZIndex();
    setTimeout(adjustLeafletZIndex, 100);

    return () => {
      map.removeControl(routingControl);
    };
  }, [currentLocation, clientLocation]);

  return (
    <div className="relative" style={{ zIndex: 1 }}>
      <MapContainer
        center={[clientLocation.lat, clientLocation.lng]}
        zoom={13}
        style={{ height: '400px', width: '100%', borderRadius: '0.5rem', zIndex: 1 }}
        ref={mapRef}
        className="leaflet-container-lowz"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[currentLocation.coords.latitude, currentLocation.coords.longitude]}>
          <Popup>Votre position</Popup>
        </Marker>
        <Marker position={[clientLocation.lat, clientLocation.lng]}>
          <Popup>{clientLocation.formattedAddress}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapView;