import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Navigation, X, MapPin } from 'lucide-react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const QuickLocation: React.FC = () => {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const handleGetLocation = () => {
    setIsLoading(true);
    setError(null);

    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur');
      setIsLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation(position);
        setIsLoading(false);
        setShowMap(true);
      },
      (err) => {
        switch (err.code) {
          case err.PERMISSION_DENIED:
            setError('Accès à la localisation refusé');
            break;
          case err.POSITION_UNAVAILABLE:
            setError('Position non disponible');
            break;
          case err.TIMEOUT:
            setError('Délai d\'attente dépassé');
            break;
          default:
            setError('Une erreur est survenue');
        }
        setIsLoading(false);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  };

  const openInOSM = () => {
    if (location) {
      const url = `https://www.openstreetmap.org/?mlat=${location.coords.latitude}&mlon=${location.coords.longitude}#map=16/${location.coords.latitude}/${location.coords.longitude}`;
      window.open(url, '_blank');
    }
  };

  return (
    <div className="relative">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleGetLocation}
        disabled={isLoading}
        className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
      >
        <Navigation className={`h-5 w-5 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
        Ma position
      </motion.button>

      <AnimatePresence>
        {(location || error) && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute mt-2 right-0 w-96 bg-white rounded-lg shadow-lg p-4 z-50"
          >
            <button
              onClick={() => {
                setLocation(null);
                setError(null);
                setShowMap(false);
              }}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
            >
              <X className="h-4 w-4" />
            </button>

            {error ? (
              <div className="text-red-600 text-sm">{error}</div>
            ) : location && (
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Position actuelle</h3>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-600 flex items-center">
                    <MapPin className="h-4 w-4 mr-1" />
                    Lat: {location.coords.latitude.toFixed(6)}
                  </p>
                  <p className="text-sm text-gray-600 flex items-center">
                    <MapPin className="h-4 w-4 mr-1" />
                    Long: {location.coords.longitude.toFixed(6)}
                  </p>
                  <p className="text-sm text-gray-600 flex items-center">
                    <Navigation className="h-4 w-4 mr-1" />
                    Précision: ±{Math.round(location.coords.accuracy)}m
                  </p>
                </div>

                {showMap && (
                  <div className="h-48 mt-2 rounded-lg overflow-hidden">
                    <MapContainer
                      center={[location.coords.latitude, location.coords.longitude]}
                      zoom={13}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker position={[location.coords.latitude, location.coords.longitude]}>
                        <Popup>Votre position actuelle</Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                )}

                <button
                  onClick={openInOSM}
                  className="w-full mt-2 px-3 py-2 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors flex items-center justify-center"
                >
                  <MapPin className="h-4 w-4 mr-2" />
                  Voir sur OpenStreetMap
                </button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default QuickLocation;