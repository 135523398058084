import React from 'react';
import { useParams } from 'react-router-dom';
import InvoiceDetails from './InvoiceDetails';

const ViewInvoice: React.FC = () => {
  const { id } = useParams();

  if (!id) return null;

  return <InvoiceDetails invoiceId={id} />;
};

export default ViewInvoice;