import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { MapPin } from 'lucide-react';
import { GeoLocation } from '../context/ClientContext';
import LocationError from './location/LocationError';
import RouteInfo from './location/RouteInfo';
import MapView from './location/MapView';
import { geocodeAddress, calculateRoute, GeocodingError } from '../utils/geocoding';

interface LocationTrackerProps {
  clientLocation?: GeoLocation;
  clientAddress: string;
  onUpdateLocation: (location: GeoLocation) => void;
}

const LocationTracker: React.FC<LocationTrackerProps> = ({
  clientLocation,
  clientAddress,
  onUpdateLocation
}) => {
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition | null>(null);
  const [distance, setDistance] = useState<string | null>(null);
  const [duration, setDuration] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  // Check Google Maps API availability
  useEffect(() => {
    if (window.google?.maps) {
      setIsGoogleMapsLoaded(true);
    } else {
      setError('Service de cartographie non disponible');
    }
  }, []);

  // Watch current location
  useEffect(() => {
    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur');
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setCurrentLocation(position);
        setError(null);
        setIsPermissionDenied(false);
      },
      (err) => {
        if (err.code === err.PERMISSION_DENIED) {
          setIsPermissionDenied(true);
          setError('Accès à la localisation refusé. Veuillez autoriser l\'accès dans les paramètres de votre navigateur pour utiliser cette fonctionnalité.');
        } else {
          setError('Impossible d\'obtenir votre position actuelle');
        }
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  // Calculate route when locations change
  useEffect(() => {
    const updateRoute = async () => {
      if (!currentLocation || !clientLocation || !isGoogleMapsLoaded) return;

      try {
        const result = await calculateRoute(
          {
            lat: currentLocation.coords.latitude,
            lng: currentLocation.coords.longitude
          },
          clientLocation
        );
        setDistance(result.distance);
        setDuration(result.duration);
        setError(null);
      } catch (err) {
        setError('Impossible de calculer l\'itinéraire');
      }
    };

    updateRoute();
  }, [currentLocation, clientLocation, isGoogleMapsLoaded]);

  const handleGeocodeAddress = async () => {
    if (!isGoogleMapsLoaded) {
      setError('Service de cartographie non disponible');
      return;
    }

    try {
      const location = await geocodeAddress(clientAddress, new google.maps.Geocoder());
      onUpdateLocation(location);
      setError(null);
    } catch (err) {
      if (err instanceof GeocodingError) {
        setError(err.message);
      } else {
        setError('Une erreur est survenue lors de la géolocalisation');
      }
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold text-gray-900 flex items-center">
          <MapPin className="h-5 w-5 mr-2 text-blue-600" />
          Localisation
        </h3>
        {!isPermissionDenied && isGoogleMapsLoaded && (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleGeocodeAddress}
            className="px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            Mettre à jour la localisation
          </motion.button>
        )}
      </div>

      {error ? (
        <LocationError message={error} isPermissionDenied={isPermissionDenied} />
      ) : (
        <div className="space-y-3">
          {distance && duration && (
            <RouteInfo distance={distance} duration={duration} />
          )}

          {clientLocation && currentLocation && (
            <div className="mt-4 space-y-4">
              <MapView
                currentLocation={currentLocation}
                clientLocation={clientLocation}
                isGoogleMapsLoaded={isGoogleMapsLoaded}
                apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationTracker;