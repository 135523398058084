import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, FileCheck, Search, Calendar, CreditCard } from 'lucide-react';
import { useQuotes } from '../../context/QuoteContext';
import { useClients } from '../../context/ClientContext';
import Loader from '../Loader';

const QuotesList: React.FC = () => {
  const navigate = useNavigate();
  const { quotes, loading, error } = useQuotes();
  const { clients } = useClients();
  const [searchTerm, setSearchTerm] = useState('');

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600">Une erreur est survenue lors du chargement des devis</p>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'accepte':
        return 'bg-green-100 text-green-800';
      case 'refuse':
        return 'bg-red-100 text-red-800';
      case 'en_attente':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: string) => {
    const labels: { [key: string]: string } = {
      'brouillon': 'Brouillon',
      'en_attente': 'En attente',
      'accepte': 'Accepté',
      'refuse': 'Refusé'
    };
    return labels[status];
  };

  const calculateTotal = (quote: any) => {
    return quote.items.reduce((sum: number, item: any) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  const getClientName = (clientId: string) => {
    const client = clients.find(c => c.id === clientId);
    return client ? `${client.firstName} ${client.lastName}` : 'Client inconnu';
  };

  const filteredQuotes = quotes.filter(quote => {
    const searchString = searchTerm.toLowerCase();
    const client = clients.find(c => c.id === quote.clientId);
    return (
      quote.number.toLowerCase().includes(searchString) ||
      (client && `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchString))
    );
  });

  return (
    <div className="bg-gradient-to-br from-amber-50 via-white to-amber-50 rounded-lg shadow-sm">
      {/* Header avec recherche et bouton d'ajout */}
      <div className="p-8 border-b border-amber-100">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <div className="relative flex-1 max-w-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-amber-400" />
            </div>
            <input
              type="text"
              placeholder="Rechercher un devis..."
              className="pl-10 block w-full rounded-md border-amber-200 shadow-sm focus:ring-amber-500 focus:border-amber-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/dashboard/quotes/new')}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            <Plus className="h-5 w-5 mr-2" />
            Nouveau devis
          </motion.button>
        </div>
      </div>

      {/* Liste des devis */}
      <div className="p-4">
        <div className="min-w-full rounded-lg overflow-hidden bg-white">
          <table className="min-w-full table-fixed divide-y divide-amber-100">
            <thead className="bg-amber-50">
              <tr>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-amber-500 uppercase tracking-wider">
                  Numéro
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-amber-500 uppercase tracking-wider">
                  Client
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-amber-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-amber-500 uppercase tracking-wider">
                  Validité
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-amber-500 uppercase tracking-wider">
                  Montant
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-amber-500 uppercase tracking-wider">
                  Statut
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-amber-50">
              <AnimatePresence>
                {filteredQuotes.map((quote) => (
                  <motion.tr
                    key={quote.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    whileHover={{ backgroundColor: 'rgba(251, 191, 36, 0.1)' }}
                    className="cursor-pointer"
                    onClick={() => navigate(`/dashboard/quotes/view/${quote.id}`)}
                  >
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <FileCheck className="h-5 w-5 text-amber-400 mr-2 flex-shrink-0" />
                        <span className="text-sm font-medium text-gray-900 truncate">
                          {quote.number}
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <span className="text-sm text-gray-900 truncate block">
                        {getClientName(quote.clientId)}
                      </span>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm text-gray-900">
                        <Calendar className="h-4 w-4 text-amber-400 mr-1 flex-shrink-0" />
                        {quote.date}
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm text-gray-900">
                        <Calendar className="h-4 w-4 text-amber-400 mr-1 flex-shrink-0" />
                        {quote.validUntil}
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm font-medium text-gray-900">
                        <CreditCard className="h-4 w-4 text-amber-400 mr-1 flex-shrink-0" />
                        {calculateTotal(quote).toFixed(2)} €
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(quote.status)}`}>
                        {getStatusLabel(quote.status)}
                      </span>
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>

          {filteredQuotes.length === 0 && (
            <div className="text-center py-12">
              <FileCheck className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun devis</h3>
              <p className="mt-1 text-sm text-gray-500">
                Commencez par créer un nouveau devis.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotesList;