import React from 'react';
import { Navigation, MapPin } from 'lucide-react';

interface RouteInfoProps {
  distance: string;
  duration: string;
}

const RouteInfo: React.FC<RouteInfoProps> = ({ distance, duration }) => (
  <>
    <div className="flex items-center space-x-2 text-gray-700">
      <Navigation className="h-5 w-5 text-blue-600" />
      <span>Distance: {distance}</span>
    </div>
    <div className="flex items-center space-x-2 text-gray-700">
      <MapPin className="h-5 w-5 text-blue-600" />
      <span>Durée estimée: {duration}</span>
    </div>
  </>
);

export default RouteInfo;