import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { GeoLocation } from '../../context/ClientContext';

// Routing Machine component with proper cleanup
const RoutingMachine: React.FC<{
  start: [number, number];
  end: [number, number];
}> = ({ start, end }) => {
  const map = useMap();
  const routingControlRef = useRef<L.Routing.Control | null>(null);

  useEffect(() => {
    if (!map) return;

    // Create routing control
    routingControlRef.current = L.Routing.control({
      waypoints: [
        L.latLng(start[0], start[1]),
        L.latLng(end[0], end[1])
      ],
      routeWhileDragging: false,
      showAlternatives: false,
      fitSelectedRoutes: true,
      lineOptions: {
        styles: [{ color: '#db2777', weight: 6 }] // pink-600
      },
      createMarker: () => null, // Disable default markers
      addWaypoints: false,
      draggableWaypoints: false
    }).addTo(map);

    // Handle route found event
    const handleRoutesFound = (e: L.Routing.RoutingResultEvent) => {
      const bounds = L.latLngBounds(e.routes[0].coordinates);
      map.fitBounds(bounds, { padding: [50, 50] });
    };

    routingControlRef.current.on('routesfound', handleRoutesFound);

    // Cleanup function
    return () => {
      if (routingControlRef.current) {
        // Remove event listener
        routingControlRef.current.off('routesfound', handleRoutesFound);

        // Remove routing control from map
        try {
          map.removeControl(routingControlRef.current);
        } catch (e) {
          console.warn('Error removing routing control:', e);
        }

        // Clear any remaining route layers
        map.eachLayer((layer) => {
          if (layer instanceof L.Polyline || layer instanceof L.LayerGroup) {
            try {
              map.removeLayer(layer);
            } catch (e) {
              console.warn('Error removing layer:', e);
            }
          }
        });

        routingControlRef.current = null;
      }
    };
  }, [map, start, end]);

  return null;
};

interface RouteMapModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentLocation: GeolocationPosition;
  destination: GeoLocation;
  clientName: string;
}

const RouteMapModal: React.FC<RouteMapModalProps> = ({
  isOpen,
  onClose,
  currentLocation,
  destination,
  clientName
}) => {
  if (!isOpen) return null;

  const startPosition: [number, number] = [
    currentLocation.coords.latitude,
    currentLocation.coords.longitude
  ];
  const endPosition: [number, number] = [destination.lat, destination.lng];

  // Calculate initial center between the two points
  const center: [number, number] = [
    (startPosition[0] + endPosition[0]) / 2,
    (startPosition[1] + endPosition[1]) / 2
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-lg shadow-xl w-full max-w-4xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold text-gray-900">
            Itinéraire vers {clientName}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          <div className="h-[600px] rounded-lg overflow-hidden">
            <MapContainer
              center={center}
              zoom={12}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={startPosition}>
                <Popup>Votre position</Popup>
              </Marker>
              <Marker position={endPosition}>
                <Popup>{destination.formattedAddress}</Popup>
              </Marker>
              <RoutingMachine start={startPosition} end={endPosition} />
            </MapContainer>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default RouteMapModal;