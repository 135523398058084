import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FileText, 
  User, 
  Building2, 
  Calendar, 
  CreditCard, 
  Check, 
  Clock, 
  Ban,
  X,
  Mail,
  Phone,
  Tag,
  Download
} from 'lucide-react';
import { useInvoices, InvoiceStatus } from '../../context/InvoiceContext';
import { useClients } from '../../context/ClientContext';
import { useActivity } from '../../context/ActivityContext';
import SendDocumentButton from '../SendDocumentButton';
import { generatePDF } from '../../utils/pdfGenerator';

interface InvoiceDetailsProps {
  invoiceId: string;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ invoiceId }) => {
  const navigate = useNavigate();
  const { invoices, updateInvoice } = useInvoices();
  const { clients } = useClients();
  const { addActivity } = useActivity();
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  
  const invoice = invoices.find(inv => inv.id === invoiceId);
  const client = invoice ? clients.find(c => c.id === invoice.clientId) : null;

  if (!invoice || !client) {
    navigate('/dashboard/invoices');
    return null;
  }

  const handleClose = () => {
    navigate('/dashboard/invoices');
  };

  const handleStatusChange = (newStatus: InvoiceStatus) => {
    updateInvoice(invoice.id, { status: newStatus });
    addActivity('invoice', 'update', `Statut de la facture ${invoice.number} mis à jour: ${newStatus}`, invoice.id);
    setIsStatusMenuOpen(false);
  };

  const handleDownload = () => {
    // Générer le PDF
    const pdfData = generatePDF({
      type: 'facture',
      number: invoice.number,
      date: invoice.date,
      dueDate: invoice.dueDate,
      client,
      items: invoice.items,
      notes: invoice.notes,
      terms: invoice.terms
    });

    // Créer un lien temporaire pour le téléchargement
    const link = document.createElement('a');
    link.href = pdfData;
    link.download = `Facture_${invoice.number}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getStatusColor = (status: InvoiceStatus) => {
    switch (status) {
      case 'payee':
        return 'bg-green-100 text-green-800';
      case 'annulee':
        return 'bg-red-100 text-red-800';
      case 'en_attente':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: InvoiceStatus) => {
    const labels: { [key in InvoiceStatus]: string } = {
      'brouillon': 'Brouillon',
      'en_attente': 'En attente',
      'payee': 'Payée',
      'annulee': 'Annulée'
    };
    return labels[status];
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
  };

  const calculateTaxes = () => {
    return invoice.items.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice * (item.tax / 100)), 0
    );
  };

  const calculateTotal = () => {
    return invoice.items.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={handleClose}
    >
      <motion.div 
        className="bg-white rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto"
        onClick={e => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
      >
        {/* En-tête */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                <FileText className="h-6 w-6 mr-2 text-violet-600" />
                Facture {invoice.number}
              </h2>
              <div className="mt-2 relative">
                <button
                  onClick={() => setIsStatusMenuOpen(!isStatusMenuOpen)}
                  className={`inline-flex items-center px-3 py-1 rounded-full ${getStatusColor(invoice.status)} transition-colors duration-200 hover:opacity-90`}
                >
                  {invoice.status === 'payee' ? (
                    <Check className="h-4 w-4 mr-2" />
                  ) : invoice.status === 'en_attente' ? (
                    <Clock className="h-4 w-4 mr-2" />
                  ) : (
                    <Ban className="h-4 w-4 mr-2" />
                  )}
                  {getStatusLabel(invoice.status)}
                </button>

                <AnimatePresence>
                  {isStatusMenuOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                    >
                      <div className="py-1">
                        {(['en_attente', 'payee', 'annulee'] as InvoiceStatus[]).map((status) => (
                          <button
                            key={status}
                            onClick={() => handleStatusChange(status)}
                            className={`w-full text-left px-4 py-2 text-sm hover:bg-gray-100 flex items-center ${
                              status === invoice.status ? 'bg-gray-50' : ''
                            }`}
                          >
                            {status === 'payee' ? (
                              <Check className="h-4 w-4 mr-2" />
                            ) : status === 'en_attente' ? (
                              <Clock className="h-4 w-4 mr-2" />
                            ) : (
                              <Ban className="h-4 w-4 mr-2" />
                            )}
                            {getStatusLabel(status)}
                          </button>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleDownload}
                className="inline-flex items-center px-3 py-1 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
              >
                <Download className="h-4 w-4 mr-2" />
                Télécharger
              </button>
              <SendDocumentButton
                type="facture"
                documentNumber={invoice.number}
                clientEmail={client.email}
                clientName={`${client.firstName} ${client.lastName}`}
                documentId={invoice.id}
              />
              <button
                onClick={handleClose}
                className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Contenu */}
        <div className="p-6 space-y-8">
          {/* Informations client et dates */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Client</h3>
              <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                <div className="flex items-center space-x-3">
                  <User className="h-5 w-5 text-violet-600" />
                  <div>
                    <p className="font-medium text-gray-900">
                      {client.firstName} {client.lastName}
                    </p>
                    <p className="text-sm text-gray-500">{client.company}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <Mail className="h-5 w-5 text-gray-400" />
                  <p className="text-gray-600">{client.email}</p>
                </div>
                <div className="flex items-center space-x-3">
                  <Phone className="h-5 w-5 text-gray-400" />
                  <p className="text-gray-600">{client.phone}</p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Dates</h3>
              <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                <div className="flex items-center space-x-3">
                  <Calendar className="h-5 w-5 text-violet-600" />
                  <div>
                    <p className="text-sm text-gray-500">Date de facturation</p>
                    <p className="font-medium text-gray-900">{invoice.date}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <Calendar className="h-5 w-5 text-gray-400" />
                  <div>
                    <p className="text-sm text-gray-500">Date d'échéance</p>
                    <p className="font-medium text-gray-900">{invoice.dueDate}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Articles */}
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Articles</h3>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Quantité
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Prix unitaire
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      TVA
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {invoice.items.map((item) => (
                    <tr key={item.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.description}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.quantity}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.unitPrice.toFixed(2)} €
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.tax}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)} €
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4 bg-gray-50 rounded-lg p-4">
              <div className="space-y-2">
                <div className="flex justify-between text-sm text-gray-600">
                  <span>Sous-total</span>
                  <span>{calculateSubtotal().toFixed(2)} €</span>
                </div>
                <div className="flex justify-between text-sm text-gray-600">
                  <span>TVA</span>
                  <span>{calculateTaxes().toFixed(2)} €</span>
                </div>
                <div className="flex justify-between text-lg font-semibold text-gray-900 pt-2 border-t border-gray-200">
                  <span>Total</span>
                  <span>{calculateTotal().toFixed(2)} €</span>
                </div>
              </div>
            </div>
          </div>

          {/* Notes et conditions */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-600 whitespace-pre-wrap">
                  {invoice.notes || 'Aucune note'}
                </p>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Conditions</h3>
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-600 whitespace-pre-wrap">
                  {invoice.terms || 'Aucune condition spécifique'}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="p-6 border-t border-gray-200">
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => navigate(`/dashboard/invoices/edit/${invoice.id}`)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-violet-600 hover:bg-violet-700"
            >
              Modifier la facture
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default InvoiceDetails;