import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Client } from '../context/ClientContext';

interface DocumentItem {
  description: string;
  quantity: number;
  unitPrice: number;
  tax: number;
}

interface DocumentData {
  type: 'devis' | 'facture';
  number: string;
  date: string;
  validUntil?: string;
  dueDate?: string;
  client: Client;
  items: DocumentItem[];
  notes?: string;
  terms?: string;
}

export const generatePDF = (data: DocumentData): string => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;

  // En-tête
  doc.setFontSize(20);
  doc.setTextColor(59, 130, 246); // blue-600
  doc.text(data.type === 'devis' ? 'DEVIS' : 'FACTURE', 20, 20);

  doc.setFontSize(12);
  doc.setTextColor(0);
  doc.text(`N° ${data.number}`, 20, 30);
  doc.text(`Date : ${format(new Date(data.date), 'dd MMMM yyyy', { locale: fr })}`, 20, 37);
  if (data.type === 'devis' && data.validUntil) {
    doc.text(`Valable jusqu'au : ${format(new Date(data.validUntil), 'dd MMMM yyyy', { locale: fr })}`, 20, 44);
  }
  if (data.type === 'facture' && data.dueDate) {
    doc.text(`Échéance : ${format(new Date(data.dueDate), 'dd MMMM yyyy', { locale: fr })}`, 20, 44);
  }

  // Informations client
  doc.setFillColor(247, 250, 252); // bg-gray-50
  doc.rect(20, 55, pageWidth - 40, 40, 'F');
  doc.setFontSize(14);
  doc.text('Client', 25, 65);
  doc.setFontSize(12);
  doc.text([
    `${data.client.firstName} ${data.client.lastName}`,
    data.client.company,
    data.client.address,
    `Email: ${data.client.email}`,
    `Tél: ${data.client.phone}`
  ], 25, 75);

  // Tableau des articles
  const tableData = data.items.map(item => [
    item.description,
    item.quantity.toString(),
    `${item.unitPrice.toFixed(2)} €`,
    `${item.tax}%`,
    `${(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)} €`
  ]);

  autoTable(doc, {
    startY: 110,
    head: [['Description', 'Quantité', 'Prix unitaire', 'TVA', 'Total']],
    body: tableData,
    theme: 'striped',
    headStyles: { fillColor: [59, 130, 246] },
    styles: { fontSize: 10 },
    columnStyles: {
      0: { cellWidth: 'auto' },
      1: { cellWidth: 20, halign: 'center' },
      2: { cellWidth: 30, halign: 'right' },
      3: { cellWidth: 20, halign: 'center' },
      4: { cellWidth: 30, halign: 'right' }
    }
  });

  // Calcul des totaux
  const subtotal = data.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
  const taxes = data.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice * (item.tax / 100)), 0);
  const total = subtotal + taxes;

  const finalY = (doc as any).lastAutoTable.finalY || 150;

  // Totaux
  doc.setFontSize(10);
  doc.text('Sous-total:', pageWidth - 80, finalY + 20);
  doc.text(`${subtotal.toFixed(2)} €`, pageWidth - 20, finalY + 20, { align: 'right' });
  doc.text('TVA:', pageWidth - 80, finalY + 27);
  doc.text(`${taxes.toFixed(2)} €`, pageWidth - 20, finalY + 27, { align: 'right' });
  doc.setFontSize(12);
  doc.setFont(undefined, 'bold');
  doc.text('Total:', pageWidth - 80, finalY + 37);
  doc.text(`${total.toFixed(2)} €`, pageWidth - 20, finalY + 37, { align: 'right' });

  // Notes et conditions
  if (data.notes || data.terms) {
    doc.setFont(undefined, 'normal');
    doc.setFontSize(10);
    let y = finalY + 60;

    if (data.notes) {
      doc.text('Notes:', 20, y);
      doc.setTextColor(100);
      doc.text(data.notes, 20, y + 7);
      y += 20;
    }

    if (data.terms) {
      doc.setTextColor(0);
      doc.text('Conditions:', 20, y);
      doc.setTextColor(100);
      doc.text(data.terms, 20, y + 7);
    }
  }

  // Pied de page
  doc.setTextColor(150);
  doc.setFontSize(8);
  doc.text('vite-gestion - Solution de gestion pour installateurs de climatisation', pageWidth / 2, doc.internal.pageSize.height - 10, { align: 'center' });

  // Convertir en base64
  return doc.output('dataurlstring');
};