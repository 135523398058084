import { GeoLocation } from '../context/ClientContext';

const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org';
const OSRM_BASE_URL = 'https://router.project-osrm.org/route/v1/driving';

export class GeocodingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'GeocodingError';
  }
}

export const geocodeAddress = async (address: string): Promise<GeoLocation> => {
  try {
    const params = new URLSearchParams({
      q: address,
      format: 'json',
      limit: '1',
      addressdetails: '1'
    });

    const response = await fetch(`${NOMINATIM_BASE_URL}/search?${params}`, {
      headers: {
        'User-Agent': 'vite-gestion',
        'Accept-Language': 'fr'
      }
    });

    if (!response.ok) {
      throw new GeocodingError('Erreur lors de la requête de géocodage');
    }

    const results = await response.json();
    
    if (!results || results.length === 0) {
      throw new GeocodingError('Aucun résultat trouvé pour cette adresse');
    }

    const location = results[0];
    return {
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lon),
      formattedAddress: location.display_name
    };
  } catch (error) {
    if (error instanceof GeocodingError) {
      throw error;
    }
    throw new GeocodingError('Erreur lors du géocodage de l\'adresse');
  }
};

export const calculateRoute = async (
  origin: { lat: number; lng: number },
  destination: { lat: number; lng: number }
): Promise<{ distance: string; duration: string; durationValue: number }> => {
  try {
    const response = await fetch(
      `${OSRM_BASE_URL}/${origin.lng},${origin.lat};${destination.lng},${destination.lat}`
    );
    
    if (!response.ok) {
      throw new Error('Erreur lors de la requête OSRM');
    }

    const data = await response.json();
    
    if (!data.routes || data.routes.length === 0) {
      throw new Error('Pas de résultat d\'itinéraire');
    }

    const route = data.routes[0];
    const distanceKm = (route.distance / 1000).toFixed(1);
    const durationMin = Math.round(route.duration / 60);

    return {
      distance: `${distanceKm} km`,
      duration: `${durationMin} min`,
      durationValue: Math.round(route.duration)
    };
  } catch (error) {
    console.error('Erreur lors du calcul de l\'itinéraire:', error);
    throw new Error('Impossible de calculer l\'itinéraire');
  }
};