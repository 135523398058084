import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Plus, Minus, Calendar, FileText, User, Building2, CreditCard } from 'lucide-react';
import { useClients } from '../../context/ClientContext';
import { useInvoices, InvoiceItem } from '../../context/InvoiceContext';
import { useActivity } from '../../context/ActivityContext';
import Loader from '../Loader';

interface FormData {
  clientId: string;
  date: string;
  dueDate: string;
  items: InvoiceItem[];
  notes: string;
  terms: string;
}

const initialItem: Omit<InvoiceItem, 'id'> = {
  description: '',
  quantity: 1,
  unitPrice: 0,
  tax: 20
};

const CreateInvoice: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clients } = useClients();
  const { addInvoice, getNextInvoiceNumber, loading } = useInvoices();
  const { addActivity } = useActivity();

  // Récupérer le clientId depuis l'état de navigation si disponible
  const defaultClientId = location.state?.clientId || '';

  const [formData, setFormData] = useState<FormData>({
    clientId: defaultClientId,
    date: new Date().toISOString().split('T')[0],
    dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    items: [{ ...initialItem, id: Date.now().toString() }],
    notes: '',
    terms: 'Conditions de paiement : 30 jours\nTVA non applicable, art. 293 B du CGI'
  });

  if (loading) {
    return <Loader />;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.clientId) return;

    try {
      const invoiceNumber = await getNextInvoiceNumber();
      const newInvoice = {
        ...formData,
        number: invoiceNumber,
        status: 'brouillon' as const
      };

      const invoiceId = await addInvoice(newInvoice);
      addActivity('invoice', 'create', 'Nouvelle facture créée', invoiceId);
      navigate('/dashboard/invoices');
    } catch (error) {
      console.error('Erreur lors de la création de la facture:', error);
    }
  };

  const handleAddItem = () => {
    setFormData(prev => ({
      ...prev,
      items: [
        ...prev.items,
        { ...initialItem, id: Date.now().toString() }
      ]
    }));
  };

  const handleRemoveItem = (id: string) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== id)
    }));
  };

  const handleItemChange = (id: string, field: keyof Omit<InvoiceItem, 'id'>, value: string | number) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    }));
  };

  const calculateTotal = () => {
    return formData.items.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-lg shadow-sm p-6"
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* En-tête */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 flex items-center">
            <FileText className="h-6 w-6 mr-2 text-violet-600" />
            Nouvelle facture
          </h2>
        </div>

        {/* Informations client */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Client</h3>
          <select
            value={formData.clientId}
            onChange={(e) => setFormData(prev => ({ ...prev, clientId: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
            required
          >
            <option value="">Sélectionner un client</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName} - {client.company}
              </option>
            ))}
          </select>
        </div>

        {/* Dates */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700">
              Date de facturation
            </label>
            <input
              type="date"
              id="date"
              value={formData.date}
              onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
              required
            />
          </div>
          <div>
            <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
              Date d'échéance
            </label>
            <input
              type="date"
              id="dueDate"
              value={formData.dueDate}
              onChange={(e) => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
              required
            />
          </div>
        </div>

        {/* Articles */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Articles</h3>
            <button
              type="button"
              onClick={handleAddItem}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-violet-600 hover:bg-violet-700"
            >
              <Plus className="h-4 w-4 mr-1" />
              Ajouter un article
            </button>
          </div>

          <div className="space-y-4">
            {formData.items.map((item) => (
              <div key={item.id} className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex-grow grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                      type="text"
                      value={item.description}
                      onChange={(e) => handleItemChange(item.id, 'description', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Quantité</label>
                    <input
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(item.id, 'quantity', Number(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Prix unitaire</label>
                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      value={item.unitPrice}
                      onChange={(e) => handleItemChange(item.id, 'unitPrice', Number(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
                      required
                    />
                  </div>
                </div>
                {formData.items.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveItem(item.id)}
                    className="mt-6 p-2 text-red-600 hover:text-red-700 rounded-full hover:bg-red-50"
                  >
                    <Minus className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="mt-4 text-right">
            <p className="text-lg font-medium text-gray-900">
              Total: {calculateTotal().toFixed(2)} €
            </p>
          </div>
        </div>

        {/* Notes et conditions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              id="notes"
              rows={4}
              value={formData.notes}
              onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
              placeholder="Notes additionnelles..."
            />
          </div>
          <div>
            <label htmlFor="terms" className="block text-sm font-medium text-gray-700">
              Conditions
            </label>
            <textarea
              id="terms"
              rows={4}
              value={formData.terms}
              onChange={(e) => setFormData(prev => ({ ...prev, terms: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-violet-500 focus:ring-violet-500"
              placeholder="Conditions de paiement..."
            />
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/dashboard/invoices')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Annuler
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-violet-600 border border-transparent rounded-md hover:bg-violet-700"
          >
            Créer la facture
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default CreateInvoice;