import React, { createContext, useContext, useCallback } from 'react';
import useFirestore from '../hooks/useFirestore';
import useStorage from '../hooks/useStorage';
import { useAuth } from './AuthContext';
import { geocodeAddress } from '../utils/geocoding';

export interface Todo {
  id: string;
  text: string;
  completed: boolean;
  createdAt: string;
}

export interface Photo {
  id: string;
  url: string;
  title: string;
  date: string;
}

export interface GeoLocation {
  lat: number;
  lng: number;
  formattedAddress?: string;
}

export interface Client {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  address: string;
  type: string;
  interventionType: 'climatiseur' | 'pompe-chaleur' | 'ventilation' | 'autre';
  status: 'actif' | 'inactif';
  lastInteraction: string;
  notes: string;
  todos: Todo[];
  photos: Photo[];
  location?: GeoLocation;
  createdAt: string;
  updatedAt: string;
}

interface ClientContextType {
  clients: Client[];
  loading: boolean;
  error: Error | null;
  addClient: (client: Omit<Client, 'id' | 'userId' | 'createdAt' | 'updatedAt' | 'lastInteraction'>) => Promise<string>;
  updateClient: (id: string, client: Partial<Client>) => Promise<void>;
  deleteClient: (id: string) => Promise<void>;
  addTodo: (clientId: string, text: string) => Promise<void>;
  toggleTodo: (clientId: string, todoId: string) => Promise<void>;
  deleteTodo: (clientId: string, todoId: string) => Promise<void>;
  addPhoto: (clientId: string, photo: Omit<Photo, 'id'>) => Promise<void>;
  deletePhoto: (clientId: string, photoId: string) => Promise<void>;
  updateClientLocation: (clientId: string, location: GeoLocation) => Promise<void>;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { documents: clients, loading, error, add, update, remove } = useFirestore<Client>({
    collection: 'clients',
    queries: [{ field: 'userId', operator: '==', value: user?.uid }],
    orderByField: 'lastInteraction',
    orderDirection: 'desc'
  });
  const { upload: uploadPhoto, remove: removePhoto } = useStorage('client-photos');

  const addClient = useCallback(async (clientData: Omit<Client, 'id' | 'userId' | 'createdAt' | 'updatedAt' | 'lastInteraction'>) => {
    if (!user) throw new Error('User must be authenticated');
    
    const now = new Date().toISOString();
    const newClient = {
      ...clientData,
      userId: user.uid,
      lastInteraction: now,
      createdAt: now,
      updatedAt: now
    };

    try {
      // Géocodage de l'adresse si elle n'a pas déjà été géocodée
      if (!clientData.location && clientData.address) {
        const location = await geocodeAddress(clientData.address);
        newClient.location = location;
      }

      return await add(newClient);
    } catch (error) {
      console.error('Error adding client:', error);
      throw error;
    }
  }, [user, add]);

  const updateClient = useCallback(async (id: string, updates: Partial<Client>) => {
    const updatedData = {
      ...updates,
      updatedAt: new Date().toISOString()
    };
    await update(id, updatedData);
  }, [update]);

  const deleteClient = useCallback(async (id: string) => {
    const client = clients.find(c => c.id === id);
    if (client?.photos) {
      // Delete all photos from storage
      await Promise.all(client.photos.map(photo => removePhoto(photo.url)));
    }
    await remove(id);
  }, [clients, remove, removePhoto]);

  const addTodo = useCallback(async (clientId: string, text: string) => {
    const client = clients.find(c => c.id === clientId);
    if (!client) return;

    const newTodo: Todo = {
      id: Date.now().toString(),
      text,
      completed: false,
      createdAt: new Date().toISOString()
    };

    await update(clientId, {
      todos: [...(client.todos || []), newTodo],
      updatedAt: new Date().toISOString()
    });
  }, [clients, update]);

  const toggleTodo = useCallback(async (clientId: string, todoId: string) => {
    const client = clients.find(c => c.id === clientId);
    if (!client) return;

    const updatedTodos = client.todos.map(todo =>
      todo.id === todoId ? { ...todo, completed: !todo.completed } : todo
    );

    await update(clientId, { 
      todos: updatedTodos,
      updatedAt: new Date().toISOString()
    });
  }, [clients, update]);

  const deleteTodo = useCallback(async (clientId: string, todoId: string) => {
    const client = clients.find(c => c.id === clientId);
    if (!client) return;

    const updatedTodos = client.todos.filter(todo => todo.id !== todoId);
    await update(clientId, { 
      todos: updatedTodos,
      updatedAt: new Date().toISOString()
    });
  }, [clients, update]);

  const addPhoto = useCallback(async (clientId: string, photoData: Omit<Photo, 'id'>) => {
    const client = clients.find(c => c.id === clientId);
    if (!client) return;

    const newPhoto: Photo = {
      ...photoData,
      id: Date.now().toString(),
    };

    await update(clientId, {
      photos: [...(client.photos || []), newPhoto],
      updatedAt: new Date().toISOString()
    });
  }, [clients, update]);

  const deletePhoto = useCallback(async (clientId: string, photoId: string) => {
    const client = clients.find(c => c.id === clientId);
    if (!client) return;

    const photo = client.photos.find(p => p.id === photoId);
    if (photo) {
      await removePhoto(photo.url);
    }

    const updatedPhotos = client.photos.filter(p => p.id !== photoId);
    await update(clientId, { 
      photos: updatedPhotos,
      updatedAt: new Date().toISOString()
    });
  }, [clients, update, removePhoto]);

  const updateClientLocation = useCallback(async (clientId: string, location: GeoLocation) => {
    await update(clientId, { 
      location,
      updatedAt: new Date().toISOString()
    });
  }, [update]);

  return (
    <ClientContext.Provider value={{
      clients,
      loading,
      error,
      addClient,
      updateClient,
      deleteClient,
      addTodo,
      toggleTodo,
      deleteTodo,
      addPhoto,
      deletePhoto,
      updateClientLocation
    }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClients = () => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClients must be used within a ClientProvider');
  }
  return context;
};