import { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../config/firebase';
import { useAuth } from '../context/AuthContext';

interface UploadProgress {
  progress: number;
  url: string | null;
  error: Error | null;
}

export const useStorage = (basePath: string) => {
  const { user } = useAuth();
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({
    progress: 0,
    url: null,
    error: null
  });

  const upload = async (file: File): Promise<string> => {
    if (!user) throw new Error('User must be authenticated');

    // Vérifier la taille du fichier (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('Le fichier ne doit pas dépasser 5MB');
    }

    // Créer un chemin unique pour le fichier
    const timestamp = Date.now();
    const fileName = `${timestamp}_${file.name}`;
    const fullPath = `users/${user.uid}/${basePath}/${fileName}`;
    const storageRef = ref(storage, fullPath);
    
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({ ...prev, progress }));
        },
        (error) => {
          setUploadProgress(prev => ({ ...prev, error }));
          reject(error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadProgress(prev => ({ ...prev, url }));
            resolve(url);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const remove = async (fileUrl: string) => {
    try {
      const fileRef = ref(storage, fileUrl);
      await deleteObject(fileRef);
    } catch (error) {
      console.error('Error removing file:', error);
      throw error;
    }
  };

  return {
    upload,
    remove,
    uploadProgress
  };
};

export default useStorage;