import React, { createContext, useContext, useCallback } from 'react';
import useFirestore from '../hooks/useFirestore';
import { useAuth } from './AuthContext';

export interface InvoiceItem {
  id: string;
  description: string;
  quantity: number;
  unitPrice: number;
  tax: number;
}

export type InvoiceStatus = 'brouillon' | 'en_attente' | 'payee' | 'annulee';

export interface Invoice {
  id: string;
  userId: string;
  number: string;
  date: string;
  dueDate: string;
  clientId: string;
  status: InvoiceStatus;
  items: InvoiceItem[];
  notes?: string;
  terms?: string;
  createdAt: string;
  updatedAt: string;
}

interface InvoiceContextType {
  invoices: Invoice[];
  loading: boolean;
  error: Error | null;
  addInvoice: (invoice: Omit<Invoice, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateInvoice: (id: string, invoice: Partial<Invoice>) => Promise<void>;
  deleteInvoice: (id: string) => Promise<void>;
  getNextInvoiceNumber: () => Promise<string>;
}

const InvoiceContext = createContext<InvoiceContextType | null>(null);

export const InvoiceProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const { documents: invoices, loading, error, add, update, remove } = useFirestore<Invoice>({
    collection: 'invoices',
    queries: [{ field: 'userId', operator: '==', value: user?.uid }],
    orderByField: 'createdAt',
    orderDirection: 'desc'
  });

  const getNextInvoiceNumber = useCallback(async () => {
    const currentYear = new Date().getFullYear();
    const lastInvoice = [...invoices]
      .filter(inv => inv.number.includes(currentYear.toString()))
      .sort((a, b) => b.number.localeCompare(a.number))[0];

    if (!lastInvoice) {
      return `FACT-${currentYear}-001`;
    }

    const lastNumber = parseInt(lastInvoice.number.split('-')[2]);
    return `FACT-${currentYear}-${(lastNumber + 1).toString().padStart(3, '0')}`;
  }, [invoices]);

  const addInvoice = useCallback(async (invoiceData: Omit<Invoice, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) throw new Error('User must be authenticated');

    const now = new Date().toISOString();
    const newInvoice = {
      ...invoiceData,
      userId: user.uid,
      createdAt: now,
      updatedAt: now
    };

    return add(newInvoice);
  }, [user, add]);

  const updateInvoice = useCallback(async (id: string, updates: Partial<Invoice>) => {
    const updatedData = {
      ...updates,
      updatedAt: new Date().toISOString()
    };
    await update(id, updatedData);
  }, [update]);

  const deleteInvoice = useCallback(async (id: string) => {
    await remove(id);
  }, [remove]);

  return (
    <InvoiceContext.Provider value={{
      invoices,
      loading,
      error,
      addInvoice,
      updateInvoice,
      deleteInvoice,
      getNextInvoiceNumber
    }}>
      {children}
    </InvoiceContext.Provider>
  );
};

export const useInvoices = () => {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw new Error('useInvoices doit être utilisé dans un InvoiceProvider');
  }
  return context;
};