import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Plus, Minus, Calendar, FileCheck, User, Building2, CreditCard } from 'lucide-react';
import { useClients } from '../../context/ClientContext';
import { useQuotes, QuoteItem } from '../../context/QuoteContext';
import { useActivity } from '../../context/ActivityContext';

interface FormData {
  clientId: number;
  date: string;
  validUntil: string;
  items: QuoteItem[];
  notes: string;
  terms: string;
}

const initialItem: Omit<QuoteItem, 'id'> = {
  description: '',
  quantity: 1,
  unitPrice: 0,
  tax: 20
};

const EditQuote: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clients } = useClients();
  const { quotes, updateQuote } = useQuotes();
  const { addActivity } = useActivity();
  const [formData, setFormData] = useState<FormData | null>(null);

  useEffect(() => {
    const quote = quotes.find(q => q.id === Number(id));
    if (quote) {
      const { id: quoteId, status, number, ...quoteData } = quote;
      setFormData(quoteData);
    } else {
      navigate('/dashboard/quotes');
    }
  }, [id, quotes, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData || formData.clientId === 0) return;

    updateQuote(Number(id), formData);
    addActivity('quote', 'update', `Devis modifié`, Number(id));
    navigate('/dashboard/quotes');
  };

  const handleAddItem = () => {
    if (!formData) return;
    setFormData(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        items: [
          ...prev.items,
          { ...initialItem, id: Math.max(...prev.items.map(i => i.id)) + 1 }
        ]
      };
    });
  };

  const handleRemoveItem = (itemId: number) => {
    if (!formData) return;
    setFormData(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        items: prev.items.filter(item => item.id !== itemId)
      };
    });
  };

  const handleItemChange = (id: number, field: keyof Omit<QuoteItem, 'id'>, value: string | number) => {
    if (!formData) return;
    setFormData(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        items: prev.items.map(item =>
          item.id === id ? { ...item, [field]: value } : item
        )
      };
    });
  };

  const calculateTotal = () => {
    if (!formData) return 0;
    return formData.items.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  if (!formData) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-lg shadow-sm p-6"
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* En-tête */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 flex items-center">
            <FileCheck className="h-6 w-6 mr-2 text-amber-600" />
            Modifier le devis
          </h2>
        </div>

        {/* Informations client */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Client</h3>
          <select
            value={formData.clientId}
            onChange={(e) => setFormData(prev => prev ? { ...prev, clientId: Number(e.target.value) } : null)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
          >
            <option value={0}>Sélectionner un client</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName} - {client.company}
              </option>
            ))}
          </select>
        </div>

        {/* Dates */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700">
              Date d'émission
            </label>
            <input
              type="date"
              id="date"
              value={formData.date}
              onChange={(e) => setFormData(prev => prev ? { ...prev, date: e.target.value } : null)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
            />
          </div>
          <div>
            <label htmlFor="validUntil" className="block text-sm font-medium text-gray-700">
              Date de validité
            </label>
            <input
              type="date"
              id="validUntil"
              value={formData.validUntil}
              onChange={(e) => setFormData(prev => prev ? { ...prev, validUntil: e.target.value } : null)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
            />
          </div>
        </div>

        {/* Articles */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Articles</h3>
            <button
              type="button"
              onClick={handleAddItem}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700"
            >
              <Plus className="h-4 w-4 mr-1" />
              Ajouter un article
            </button>
          </div>

          <div className="space-y-4">
            {formData.items.map((item) => (
              <div key={item.id} className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex-grow grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                      type="text"
                      value={item.description}
                      onChange={(e) => handleItemChange(item.id, 'description', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Quantité</label>
                    <input
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(item.id, 'quantity', Number(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Prix unitaire</label>
                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      value={item.unitPrice}
                      onChange={(e) => handleItemChange(item.id, 'unitPrice', Number(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                    />
                  </div>
                </div>
                {formData.items.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveItem(item.id)}
                    className="mt-6 p-2 text-red-600 hover:text-red-700 rounded-full hover:bg-red-50"
                  >
                    <Minus className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="mt-4 text-right">
            <p className="text-lg font-medium text-gray-900">
              Total: {calculateTotal().toFixed(2)} €
            </p>
          </div>
        </div>

        {/* Notes et conditions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              id="notes"
              rows={4}
              value={formData.notes}
              onChange={(e) => setFormData(prev => prev ? { ...prev, notes: e.target.value } : null)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
              placeholder="Notes additionnelles..."
            />
          </div>
          <div>
            <label htmlFor="terms" className="block text-sm font-medium text-gray-700">
              Conditions
            </label>
            <textarea
              id="terms"
              rows={4}
              value={formData.terms}
              onChange={(e) => setFormData(prev => prev ? { ...prev, terms: e.target.value } : null)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
              placeholder="Conditions de validité..."
            />
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/dashboard/quotes')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Annuler
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-amber-600 border border-transparent rounded-md hover:bg-amber-700"
          >
            Enregistrer les modifications
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default EditQuote;