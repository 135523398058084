import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Loader from './Loader';
import LoaderDashboard from './LoaderDashboard';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, loading: authLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const initializeRoute = async () => {
      // Si l'authentification est toujours en cours, on attend
      if (authLoading) return;

      // Si l'utilisateur est authentifié, on attend au moins 2 secondes
      if (user) {
        timer = setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        // Si non authentifié, pas besoin d'attendre
        setLoading(false);
      }
    };

    initializeRoute();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [user, authLoading]);

  if (loading || authLoading) {
    // Utiliser LoaderDashboard pour le dashboard, Loader pour les autres routes protégées
    return location.pathname.startsWith('/dashboard') ? <LoaderDashboard /> : <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;