import React, { createContext, useContext } from 'react';
import useFirestore from '../hooks/useFirestore';
import { useAuth } from './AuthContext';

export interface CalendarEvent {
  id: string;
  userId: string;
  title: string;
  start: string;
  end: string;
  clientId?: string;
  description?: string;
  location?: string;
  type: 'meeting' | 'installation' | 'maintenance' | 'depannage' | 'other';
  status: 'scheduled' | 'completed' | 'cancelled';
  createdAt: string;
  updatedAt: string;
}

interface CalendarContextType {
  events: CalendarEvent[];
  loading: boolean;
  error: Error | null;
  addEvent: (event: Omit<CalendarEvent, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateEvent: (id: string, event: Partial<CalendarEvent>) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
}

const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

export const CalendarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { documents: events, loading, error, add, update, remove } = useFirestore<CalendarEvent>({
    collection: 'events',
    queries: [{ field: 'userId', operator: '==', value: user?.uid }],
    orderByField: 'start',
    orderDirection: 'asc'
  });

  const addEvent = async (eventData: Omit<CalendarEvent, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) throw new Error('User must be authenticated');

    const now = new Date().toISOString();
    const newEvent = {
      ...eventData,
      userId: user.uid,
      start: new Date(eventData.start).toISOString(),
      end: new Date(eventData.end).toISOString(),
      createdAt: now,
      updatedAt: now
    };

    return add(newEvent);
  };

  const updateEvent = async (id: string, updates: Partial<CalendarEvent>) => {
    const updatedData = {
      ...updates,
      updatedAt: new Date().toISOString()
    };
    await update(id, updatedData);
  };

  const deleteEvent = async (id: string) => {
    await remove(id);
  };

  return (
    <CalendarContext.Provider value={{
      events,
      loading,
      error,
      addEvent,
      updateEvent,
      deleteEvent
    }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }
  return context;
};