import React, { createContext, useContext, useState, useCallback } from 'react';

export type ActivityType = 'client' | 'quote' | 'invoice' | 'task';
export type ActivityAction = 'create' | 'update' | 'delete';

export interface Activity {
  id: number;
  type: ActivityType;
  action: ActivityAction;
  description: string;
  entityId: number;
  timestamp: string;
}

interface ActivityContextType {
  activities: Activity[];
  addActivity: (type: ActivityType, action: ActivityAction, description: string, entityId: number) => void;
  getRecentActivities: (limit?: number) => Activity[];
}

const ActivityContext = createContext<ActivityContextType | null>(null);

export const ActivityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activities, setActivities] = useState<Activity[]>([]);

  const addActivity = useCallback((
    type: ActivityType,
    action: ActivityAction,
    description: string,
    entityId: number
  ) => {
    const newActivity: Activity = {
      id: Date.now(),
      type,
      action,
      description,
      entityId,
      timestamp: new Date().toISOString()
    };

    setActivities(prev => [newActivity, ...prev]);
  }, []);

  const getRecentActivities = useCallback((limit: number = 10) => {
    return activities
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      .slice(0, limit);
  }, [activities]);

  return (
    <ActivityContext.Provider value={{ activities, addActivity, getRecentActivities }}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = () => {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivity must be used within an ActivityProvider');
  }
  return context;
};