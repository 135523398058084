import React, { createContext, useContext, useCallback } from 'react';
import useFirestore from '../hooks/useFirestore';
import { useAuth } from './AuthContext';

export interface QuoteItem {
  id: string;
  description: string;
  quantity: number;
  unitPrice: number;
  tax: number;
}

export type QuoteStatus = 'brouillon' | 'en_attente' | 'accepte' | 'refuse';

export interface Quote {
  id: string;
  userId: string;
  number: string;
  date: string;
  validUntil: string;
  clientId: string;
  status: QuoteStatus;
  items: QuoteItem[];
  notes?: string;
  terms?: string;
  createdAt: string;
  updatedAt: string;
}

interface QuoteContextType {
  quotes: Quote[];
  loading: boolean;
  error: Error | null;
  addQuote: (quote: Omit<Quote, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateQuote: (id: string, quote: Partial<Quote>) => Promise<void>;
  deleteQuote: (id: string) => Promise<void>;
  getNextQuoteNumber: () => Promise<string>;
}

const QuoteContext = createContext<QuoteContextType | null>(null);

export const QuoteProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const { documents: quotes, loading, error, add, update, remove } = useFirestore<Quote>({
    collection: 'quotes',
    queries: [{ field: 'userId', operator: '==', value: user?.uid }],
    orderByField: 'createdAt',
    orderDirection: 'desc'
  });

  const getNextQuoteNumber = useCallback(async () => {
    const currentYear = new Date().getFullYear();
    const lastQuote = [...quotes]
      .filter(q => q.number.includes(currentYear.toString()))
      .sort((a, b) => b.number.localeCompare(a.number))[0];

    if (!lastQuote) {
      return `DEV-${currentYear}-001`;
    }

    const lastNumber = parseInt(lastQuote.number.split('-')[2]);
    return `DEV-${currentYear}-${(lastNumber + 1).toString().padStart(3, '0')}`;
  }, [quotes]);

  const addQuote = useCallback(async (quoteData: Omit<Quote, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!user) throw new Error('User must be authenticated');

    const now = new Date().toISOString();
    const newQuote = {
      ...quoteData,
      userId: user.uid,
      createdAt: now,
      updatedAt: now
    };

    return add(newQuote);
  }, [user, add]);

  const updateQuote = useCallback(async (id: string, updates: Partial<Quote>) => {
    const updatedData = {
      ...updates,
      updatedAt: new Date().toISOString()
    };
    await update(id, updatedData);
  }, [update]);

  const deleteQuote = useCallback(async (id: string) => {
    await remove(id);
  }, [remove]);

  return (
    <QuoteContext.Provider value={{
      quotes,
      loading,
      error,
      addQuote,
      updateQuote,
      deleteQuote,
      getNextQuoteNumber
    }}>
      {children}
    </QuoteContext.Provider>
  );
};

export const useQuotes = () => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error('useQuotes doit être utilisé dans un QuoteProvider');
  }
  return context;
};