import React from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import ClientsList from '../../components/ClientsList';
import CreateClient from '../../components/CreateClient';
import EditClient from '../../components/EditClient';
import ViewClient from '../../components/ViewClient';

const ClientsManager: React.FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  const getBreadcrumbText = (segment: string) => {
    switch (segment) {
      case 'new':
        return 'Nouveau client';
      case 'edit':
        return 'Modifier client';
      case 'view':
        return 'Détails client';
      default:
        return segment.charAt(0).toUpperCase() + segment.slice(1);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-white to-emerald-50">
      {/* Breadcrumbs */}
      <nav className="p-4">
        <ol className="flex items-center space-x-2 text-sm">
          <li>
            <Link to="/dashboard" className="text-emerald-600 hover:text-emerald-700">
              Dashboard
            </Link>
          </li>
          {pathSegments.slice(1).map((segment, index) => (
            <React.Fragment key={index}>
              <ChevronRight className="h-4 w-4 text-gray-400" />
              <li>
                <span className="text-gray-700">
                  {getBreadcrumbText(segment)}
                </span>
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>

      {/* Header */}
      <div className="mb-8 relative p-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative z-10"
        >
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-emerald-600 to-emerald-400">
            Gestion des Clients
          </h1>
          <p className="mt-2 text-gray-600">
            Gérez vos clients et leurs informations en un seul endroit
          </p>
        </motion.div>
      </div>

      <div className="container mx-auto px-4 py-6">
        <Routes>
          <Route index element={<ClientsList />} />
          <Route path="new" element={<CreateClient />} />
          <Route path="edit/:id" element={<EditClient />} />
          <Route path="view/:id" element={<ViewClient />} />
        </Routes>
      </div>
    </div>
  );
};

export default ClientsManager;