import React from 'react';
import { AlertTriangle } from 'lucide-react';

interface LocationErrorProps {
  message: string;
  isPermissionDenied?: boolean;
}

const LocationError: React.FC<LocationErrorProps> = ({ message, isPermissionDenied }) => (
  <div className="bg-red-50 border border-red-200 rounded-lg p-4">
    <div className="flex items-center space-x-2 text-red-600">
      <AlertTriangle className="h-5 w-5 flex-shrink-0" />
      <p className="text-sm">{message}</p>
    </div>
    {isPermissionDenied && (
      <p className="mt-2 text-sm text-red-500">
        Pour réactiver la localisation :
        <ol className="list-decimal ml-5 mt-1">
          <li>Cliquez sur l'icône de cadenas dans la barre d'adresse</li>
          <li>Sélectionnez "Paramètres du site"</li>
          <li>Trouvez "Localisation" et changez le paramètre sur "Autoriser"</li>
        </ol>
      </p>
    )}
  </div>
);

export default LocationError;