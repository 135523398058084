import React, { createContext, useContext, useState, useCallback } from 'react';

export interface Task {
  id: number;
  title: string;
  description?: string;
  completed: boolean;
  priority: 'low' | 'medium' | 'high';
  dueDate?: string;
  clientId?: number;
  createdAt: string;
}

interface TaskContextType {
  tasks: Task[];
  addTask: (task: Omit<Task, 'id' | 'createdAt'>) => void;
  updateTask: (id: number, task: Partial<Task>) => void;
  deleteTask: (id: number) => void;
  toggleTask: (id: number) => void;
}

const TaskContext = createContext<TaskContextType | null>(null);

const INITIAL_TASKS: Task[] = [
  {
    id: 1,
    title: 'Contacter le client pour maintenance',
    description: 'Suivi maintenance climatisation',
    completed: false,
    priority: 'high',
    dueDate: '2024-03-20',
    clientId: 1,
    createdAt: '2024-03-15'
  },
  {
    id: 2,
    title: 'Préparer devis installation',
    description: 'Nouveau système de climatisation',
    completed: false,
    priority: 'medium',
    dueDate: '2024-03-22',
    clientId: 2,
    createdAt: '2024-03-15'
  }
];

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tasks, setTasks] = useState<Task[]>(INITIAL_TASKS);

  const addTask = useCallback((newTask: Omit<Task, 'id' | 'createdAt'>) => {
    setTasks(currentTasks => [
      ...currentTasks,
      {
        ...newTask,
        id: Math.max(...currentTasks.map(t => t.id)) + 1,
        createdAt: new Date().toISOString().split('T')[0]
      }
    ]);
  }, []);

  const updateTask = useCallback((id: number, updatedFields: Partial<Task>) => {
    setTasks(currentTasks =>
      currentTasks.map(task =>
        task.id === id ? { ...task, ...updatedFields } : task
      )
    );
  }, []);

  const deleteTask = useCallback((id: number) => {
    setTasks(currentTasks => currentTasks.filter(task => task.id !== id));
  }, []);

  const toggleTask = useCallback((id: number) => {
    setTasks(currentTasks =>
      currentTasks.map(task =>
        task.id === id ? { ...task, completed: !task.completed } : task
      )
    );
  }, []);

  return (
    <TaskContext.Provider value={{
      tasks,
      addTask,
      updateTask,
      deleteTask,
      toggleTask
    }}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTasks = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error('useTasks must be used within a TaskProvider');
  }
  return context;
};