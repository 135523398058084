import React from 'react';
import { useParams } from 'react-router-dom';
import QuoteDetails from './QuoteDetails';

const ViewQuote: React.FC = () => {
  const { id } = useParams();

  if (!id) return null;

  return <QuoteDetails quoteId={id} />;
};

export default ViewQuote;