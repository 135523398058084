import {
  collection,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  DocumentData,
  QuerySnapshot,
  onSnapshot,
  WhereFilterOp,
  enableNetwork,
  disableNetwork,
  getDocsFromCache,
  getDocsFromServer,
  serverTimestamp
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../config/firebase';

interface Query {
  field: string;
  operator: WhereFilterOp;
  value: any;
}

interface UseFirestoreOptions {
  collection: string;
  queries?: Query[];
  orderByField?: string;
  orderDirection?: 'asc' | 'desc';
}

export const useFirestore = <T extends DocumentData>({
  collection: collectionName,
  queries = [],
  orderByField,
  orderDirection = 'asc'
}: UseFirestoreOptions) => {
  const [documents, setDocuments] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Handle online/offline state
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      enableNetwork(db);
    };
    const handleOffline = () => {
      setIsOnline(false);
      disableNetwork(db);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};

    const setupSubscription = async () => {
      try {
        let q = query(collection(db, collectionName));

        // Add queries
        queries.forEach(({ field, operator, value }) => {
          if (value !== undefined && value !== null) {
            q = query(q, where(field, operator, value));
          }
        });

        // Add ordering if specified
        if (orderByField) {
          q = query(q, orderBy(orderByField, orderDirection));
        }

        // Try to get data from cache first
        try {
          const cachedDocs = await getDocsFromCache(q);
          const results: T[] = [];
          cachedDocs.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() } as T);
          });
          setDocuments(results);
          setLoading(false);
        } catch (cacheError) {
          console.log('No cached data available, fetching from server...');
        }

        // Set up real-time listener
        unsubscribe = onSnapshot(
          q,
          { includeMetadataChanges: true },
          async (snapshot: QuerySnapshot<DocumentData>) => {
            if (snapshot.metadata.fromCache && !snapshot.metadata.hasPendingWrites) {
              // If we're offline and don't have pending writes, try to get fresh data
              try {
                const freshDocs = await getDocsFromServer(q);
                const results: T[] = [];
                freshDocs.forEach((doc) => {
                  results.push({ id: doc.id, ...doc.data() } as T);
                });
                setDocuments(results);
              } catch (serverError) {
                console.log('Could not fetch from server, using cached data');
              }
            } else {
              const results: T[] = [];
              snapshot.forEach((doc) => {
                results.push({ id: doc.id, ...doc.data() } as T);
              });
              setDocuments(results);
            }
            setLoading(false);
            setError(null);
          },
          (err: Error) => {
            console.error('Firestore subscription error:', err);
            setError(err);
            setLoading(false);
          }
        );
      } catch (err) {
        console.error('Error setting up subscription:', err);
        setError(err instanceof Error ? err : new Error('Unknown error'));
        setLoading(false);
      }
    };

    setupSubscription();
    return () => unsubscribe();
  }, [collectionName, JSON.stringify(queries), orderByField, orderDirection]);

  const add = async (data: Omit<T, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, collectionName), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      return docRef.id;
    } catch (err) {
      console.error('Error adding document:', err);
      throw err;
    }
  };

  const update = async (id: string, data: Partial<T>) => {
    try {
      const docRef = doc(db, collectionName, id);
      await updateDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp()
      });
    } catch (err) {
      console.error('Error updating document:', err);
      throw err;
    }
  };

  const remove = async (id: string) => {
    try {
      const docRef = doc(db, collectionName, id);
      await deleteDoc(docRef);
    } catch (err) {
      console.error('Error removing document:', err);
      throw err;
    }
  };

  return {
    documents,
    loading,
    error,
    isOnline,
    add,
    update,
    remove
  };
};

export default useFirestore;