import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Home, Users, FileText, Settings, Menu, X, FileCheck, Calendar, Clock } from 'lucide-react';

const navigation = [
  { 
    name: 'Accueil', 
    href: '/dashboard', 
    icon: Home,
    color: 'text-blue-500'
  },
  { 
    name: 'Clients', 
    href: '/dashboard/clients', 
    icon: Users,
    color: 'text-emerald-500'
  },
  { 
    name: 'Devis', 
    href: '/dashboard/quotes', 
    icon: FileCheck,
    color: 'text-amber-500'
  },
  { 
    name: 'Factures', 
    href: '/dashboard/invoices', 
    icon: FileText,
    color: 'text-violet-500'
  },
  { 
    name: 'Tâches', 
    href: '/dashboard/tasks', 
    icon: FileText,
    color: 'text-red-500'
  },
  { 
    name: 'Agenda', 
    href: '/dashboard/calendar', 
    icon: Calendar,
    color: 'text-indigo-500'
  },
  { 
    name: 'Mes rendez-vous', 
    href: '/dashboard/appointments', 
    icon: Clock,
    color: 'text-pink-500'
  },
  { 
    name: 'Paramètres', 
    href: '/dashboard/settings', 
    icon: Settings,
    color: 'text-gray-500'
  },
];

const MobileNav = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) => {
  return (
    <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40" onClick={() => setIsOpen(false)} />
      <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-lg z-50 p-4">
        <div className="flex justify-end mb-4">
          <button onClick={() => setIsOpen(false)} className="p-2 rounded-full hover:bg-gray-100">
            <X className="h-6 w-6" />
          </button>
        </div>
        <nav className="space-y-2">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                `flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                  isActive
                    ? `${item.color} bg-gray-50`
                    : 'text-gray-600 hover:bg-gray-50'
                }`
              }
              onClick={() => setIsOpen(false)}
            >
              <item.icon className="h-5 w-5 mr-3" />
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

const DashboardNav: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="sticky top-0 bg-white shadow-sm z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between lg:justify-start items-center h-16">
          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:space-x-8">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) =>
                  `relative flex items-center px-3 py-2 text-sm font-medium transition-all duration-200 ${
                    isActive ? item.color : 'text-gray-600'
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <div className="relative flex items-center">
                      <item.icon className="h-5 w-5 mr-2" />
                      {item.name}
                    </div>
                    {isActive && (
                      <motion.div
                        layoutId="nav-indicator"
                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-current"
                        transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                      />
                    )}
                  </>
                )}
              </NavLink>
            ))}
          </div>

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <MobileNav isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
    </nav>
  );
};

export default DashboardNav;